@import "style.css";
@import "/style/trs/variables.css";

@media (min-width: 768px) {
    .footer {
        background-image: url(/static/build/img/footer-bg-trs.svg);
        background-size: auto 260px;
    }
}

.wysiwyg a {
    font-family: var(--font-default-bold);
}